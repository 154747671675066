import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter as Router} from "react-router-dom";
import App from "./App";
import Copyright from "./component/common/Copyright";

ReactDOM.render(
    <Router>
        <App/>
    </Router>,
    document.getElementById('root')
);

ReactDOM.render(
    <Copyright/>,
    document.getElementById('copyright')
);
