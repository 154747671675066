import React from 'react';

class Copyright extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentYear: new Date().getFullYear()
        };
    }

    render() {
        const {currentYear} = this.state;
        return (
            <p>
                © {currentYear} enklabord.se
            </p>
        );
    }

}


export default Copyright;