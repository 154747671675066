import React, {Component} from "react";
import InfoCard from "./component/common/InfoCard";

class App extends Component {

    render() {
        return (
            <InfoCard/>
        );
    }
}

export default App;