import React from 'react';

class InfoCard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            nextYear: new Date().getFullYear() + 1
        };
    }

    render() {
        const {nextYear} = this.state;
        return (
            <div className="row justify-content-md-center">
                <div className="col col-lg-2"/>
                <div className="col-lg-8">
                    <div className="card shadow-lg">
                        <div className="card-header">
                            <span className="badge rounded-pill bg-primary">info</span>
                        </div>
                        <div className="card-body">
                            <p className="card-text">
                                Enklabord.se är ett litet snickeri som tillverkar möbler och inredning huvudsakligen i
                                massivt trä.
                                <br/>
                                Produkterna beställs måttanpassade enligt dina behov via websidan.
                                <br/>
                                <br/>
                                Vi befinner oss just nu i en uppbyggnadsfas och därför finns inte så mycket information
                                här just nu. Verksamheten planeras starta under {nextYear}.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col col-lg-2"/>
            </div>
        );
    }

}


export default InfoCard;